// Those are the data that would store in font end

// Initialize Link
exports.initialize = "";

// Link for large picture
exports.largePicture = "";

// Link for small picture
exports.smallPicture = "";

// Link for verification
exports.verify = "";

// Link for element's id that the zcapt would be created
exports.id = "";

// Verification ID
exports.authID = "";

// Small picture initial position in X
exports.smallPictureInitialPositionX = 0;

// Small picture initial position in Y
exports.smallPictureInitialPositionY = 0;

// verified?
exports.isVerified = false;